<template>
  <ClientOnly>
    <LiteYouTubeEmbed
      :id="id"
      :title="decoded.title"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import LiteYouTubeEmbed from 'vue-lite-youtube-embed';
import 'vue-lite-youtube-embed/style.css';

import { PropType } from "nuxt/dist/app/compat/capi";

const props = defineProps({
  data: {
    type: String as PropType<string>,
    required: true
  }
});

const decoded = computed(() => JSON.parse(props.data));
const id = computed(() => decoded.value.url && (new URL(decoded.value.url).searchParams.get('v')));
</script>
